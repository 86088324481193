import React, { useEffect, useState, useCallback } from 'react';
import Stack, {
  onEntryChange,
} from '@shared/contentstack/live-preview/live-preview';
import { LCLanding } from '@cms-components/learning-center/lc-landing/lc-landing';
import { lcLandingMapper } from '@cms-components/learning-center/lc-landing/mappers/lc-landing.mapper';
import {
  FEATURED_CARDS_CATEGORIES_MOCK,
  FEATURED_CARDS_MOCK,
} from '@shared/contentstack/live-preview/mock-data/featured-cards-mock';

const LearningCenterLandingPreviewPage = (props: any) => {
  const { pageContext } = props;

  const [livePreviewData, setLivePreviewData] = useState<any>(null);

  Stack.startLivePreview(true);

  const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
      contentTypeUid: 'learning_center',
      url:
        pageContext?.url && pageContext?.url !== '/'
          ? pageContext?.url
          : '/live-preview',
    })) as any;

    const result = response[0][0];
    setLivePreviewData(result);
  }, [pageContext]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  return !!livePreviewData ? (
    <LCLanding
      {...lcLandingMapper({
        pageContext: {
          ...livePreviewData,
          articles: FEATURED_CARDS_MOCK,
          sections: FEATURED_CARDS_CATEGORIES_MOCK,
          site_configuration: {
            managed_key_value_pairs: {
              value: [
                { key: 'partner_page_all', value: 'All' },
                { key: 'load_more_button', value: 'Load More' },
                { key: 'og_site_name', value: 'Assembly Software' },
                { key: 'twitter_card', value: 'summary_large_image' },
                { key: 'twitter_site', value: '@NeedlesCMS' },
                { key: 'mobile_all_link', value: 'See All' },
              ],
            },
          },
        },
      })}
    />
  ) : (
    <></>
  );
};

export default LearningCenterLandingPreviewPage;
