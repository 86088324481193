import { CSLCArticle, CSLCContentType } from '@src/shared/contentstack';

export const getArticleData = ({
  featured_image,
  topics,
  headline,
  path,
  teaser_text,
  logo_card,
}: CSLCArticle) => ({
  image: featured_image.image,
  topics: topics?.map(({ display_name }) => display_name),
  headline,
  headlineLink: path,
  teaser: teaser_text,
  logoCard: {
    image: logo_card?.logo,
    background: logo_card?.card_color?.colors,
  },
});

export const getArticlesByCategory = (
  articles: CSLCArticle[],
  categoryId: string
) =>
  articles.filter(
    ({ lc_content_type }) => lc_content_type[0]?.id === categoryId
  );

// export const getCategory = (
//   categories: CSLCContentType[],
//   categoryId: string
// ) => categories.filter(({ id }) => id === categoryId)[0];
