import React from 'react';
import {
  LCLandingDataProps,
  LCLandingData,
} from '@cms-components/learning-center/lc-landing/lc-landing.interface';
import { lcLandingMapper } from '@cms-components/learning-center/lc-landing/mappers/lc-landing.mapper';
import { LCLandingContainer } from '@cms-components/learning-center/lc-landing/lc-landing.styles';
import { LCMenu } from '@shared/components/learning-center/lc-menu/lc-menu';
import { TopicsFilterProvider } from '@shared/components/learning-center/context/topics-filter.context';
import {
  LCCarouselBanner,
  LCCarouselArticleList,
  LCFilteredArticleList,
} from '@src/shared/components';
import { useLanding } from './hooks/useLanding';
import {
  filterDefinedComponents,
  mappedComponent,
} from '@shared/utilities/page.util';

export const LCLanding = (props: LCLandingData) => {
  const {
    topics,
    menu,
    bannerArticles,
    sections,
    landing_page_components,
    filtered_page_components,
    fallbackImage,
    defaultMobileAllLink,
    ...resources
  } = props || {};
  const { hasFilters, sectionsFiltered } = useLanding({
    ...resources,
    fallbackImage,
  });

  return (
    <LCLandingContainer>
      <LCMenu
        filters={topics}
        menu={menu}
        logoText="Learning Center"
        backgroundColorMenu="dark"
        backgroundColorFilter={hasFilters ? 'light' : 'dark'}
      />

      {!hasFilters && (
        <>
          <LCCarouselBanner
            articleList={bannerArticles}
            fallbackImage={fallbackImage}
          />

          {sections.map((section, index) => (
            <LCCarouselArticleList
              {...section}
              key={index}
              defaultMobileAllLink={defaultMobileAllLink}
            />
          ))}
        </>
      )}

      {hasFilters &&
        sectionsFiltered.map((section, index) => (
          <LCFilteredArticleList
            {...section}
            key={index}
            fallbackImage={fallbackImage}
          />
        ))}
      {/* Require simple component */}
      {filterDefinedComponents(
        hasFilters ? filtered_page_components : landing_page_components
      ).map((comp: any, index: number) =>
        mappedComponent(comp.type, comp, index)
      )}
    </LCLandingContainer>
  );
};

export const MappedLCLanding = ({ pageContext }: LCLandingDataProps) => {
  const data = lcLandingMapper({ pageContext });
  const { topics } = data || {};

  return (
    <TopicsFilterProvider topics={topics}>
      <LCLanding {...data} />
    </TopicsFilterProvider>
  );
};
