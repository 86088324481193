import { useTopicsFilter } from '@src/shared/components/learning-center/context/topics-filter.context';
import { UseLandingProps } from './../lc-landing.interface';
import {
  getArticleData,
  getArticlesByCategory,
} from './../utilities/article.utils';

export const useLanding = ({ articles, sectionsToFilter }: UseLandingProps) => {
  const maxFilteredArticles = 4;
  const { activeItems } = useTopicsFilter();
  const activeItemsId = activeItems
    .filter(({ active }) => active)
    .map(({ id }) => id);

  return {
    hasFilters: !activeItems.every(({ active }) => !active),
    sectionsFiltered: sectionsToFilter
      .filter((section) => section.section_type.length)
      .map(
        ({
          background_color,
          section_title,
          section_type,
          suppress_images,
        }) => {
          const category = section_type[0];
          const articleList = getArticlesByCategory(articles, category?.id);

          return {
            variant: background_color,
            title: section_title,
            isSuppressImages: suppress_images,
            numberOfResults: articleList?.length,
            navigationName: category?.navigation_name,
            seeAllLink: category?.url_segment,
            articleList: articleList
              .filter((article) => {
                return !article.topics.every(
                  ({ id }) => !activeItemsId.includes(id)
                );
              })
              .map((article) => getArticleData(article))
              .slice(0, maxFilteredArticles),
          };
        }
      ),
  };
};
