import { menuNavMapper } from '@src/shared/mappers/menuNav.mapper';
import {
  LCLandingData,
  LCLandingDataProps,
} from '@cms-components/learning-center/lc-landing/lc-landing.interface';
import {
  getArticleData,
  getArticlesByCategory,
} from './../utilities/article.utils';
import { LCArticleTileProps } from '@shared/components/learning-center/lc-article-tile/lc-article-tile.interface';
import { CSLCContentType } from '@src/shared/contentstack';
import { LCCategorySections } from '@cms-components/learning-center/lc-category/lc-category.interface';

export const lcLandingMapper = ({
  pageContext,
}: LCLandingDataProps): LCLandingData => {
  const {
    articles,
    categories,
    featured_carousel,
    learning_center_section,
    sections,
    page_url: rootPage,
    landing_page_components,
    filtered_page_components,
    site_configuration,
  } = pageContext || {};

  const lcTopicsMap = articles
    .map((article) => article.topics)
    .filter((item: any) => !!item[0])
    .map((item: any) => item[0]);

  let lcTopicsFilter: any = {};
  lcTopicsMap.forEach((element) => {
    lcTopicsFilter[element.id] = element;
  });

  const lcTopics = Object.values(lcTopicsFilter)?.map((item: any) => ({
    ...item,
  }));

  const { menu, topics } = menuNavMapper(
    lcTopics,
    sections as any,
    rootPage
  );
  const featuredCarouselIds = featured_carousel?.map(({ id }) => id);
  const { value: defaultMobileAllLink } =
    site_configuration?.managed_key_value_pairs?.value.find(
      (item: any) => item.key === 'mobile_all_link'
    );

  return {
    menu,
    topics,
    articles,
    categories,
    landing_page_components,
    filtered_page_components,
    bannerArticles: articles
      .filter(({ id }) => featuredCarouselIds.includes(id))
      .map((article) => getArticleData(article)),
    sections: learning_center_section
      .filter((section) => section.section_type.length)
      .map(
        ({
          background_color,
          section_title,
          section_type,
          see_all_link_text,
          suppress_images,
        }) => {
          const category: CSLCContentType = section_type[0];
          return {
            variant: background_color,
            title: section_title,
            isSuppressImages: suppress_images,
            seeAllLinkText: see_all_link_text,
            seeAllLink: category.url_segment,
            articleList: getArticlesByCategory(articles, category.id).map(
              (article) => getArticleData(article)
            ) as LCArticleTileProps[],
            defaultMobileAllLink,
            fallbackImage: site_configuration?.featured_article_fallback_image,
          };
        }
      ),
    sectionsToFilter: learning_center_section,
    fallbackImage: site_configuration?.featured_article_fallback_image,
    defaultMobileAllLink,
  };
};
